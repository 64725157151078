import React from 'react'


import AddressActions from '../../actions/AddressActions.js'

import {Input} from '../UI/index.js'
import {P, S2, Colors, IconButton} from '../UI/index.js'
import guid from '../../../utils/guid.js'

class PostalCodeAutofill extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            edit: false,
            address: props.address ? JSON.parse(JSON.stringify(props.address)) : {postalCode: '', nr: '', addition: '', country: 'NL', position: null}
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.address) !== JSON.stringify(this.props.address)) {
            this.setState({address: JSON.parse(JSON.stringify(this.props.address))})
        }
    }

    setPostalCode(event) {
        const {address} = this.state
        let postalCode = event.target.value.toUpperCase()
        if (/^[0-9]{4}[A-Z]{2}$/.test(postalCode)) {
            postalCode = postalCode.substr(0, 4) + ' ' + postalCode.substr(4, 2)
        }
        address.postalCode = postalCode
        delete address.position

        this.setState({address}, () => {
            this.autocomplete(address)
        })
    }

    setNr(event) {
        const {address} = this.state
        address.nr = event.target.value.toUpperCase()
        delete address.position

        this.setState({address}, () => {
            this.autocomplete(address)
        })
    }

    setAddition(event) {
        const {address} = this.state
        address.addition = event.target.value.toUpperCase()

        if (/[0-9]/.test(address.addition[0])) {
            address.addition = `-${address.addition}`
        } else if (address.addition[1] && /[-]/.test(address.addition[0]) && /[^0-9]/.test(address.addition[1])) {
            address.addition = address.addition.substr(1)
        }

        this.setState({address}, () => {
            this.autocomplete(address)
        })
    }

    autocomplete() {
        const {address} = this.state

        if (/^[0-9]{4} [A-Z]{2}$/.test(address.postalCode) && address.nr) {
            address.country = 'NL'

            this.lastCallbackId = guid.generate()

            AddressActions.autocomplete(address, this.lastCallbackId, (err, addr, callbackId) => {
                if (err || callbackId !== this.lastCallbackId) {
                    return
                }
                // Important
                const address = JSON.parse(JSON.stringify(this.state.address))

                if (addr && addr.street && addr.city) {
                    address.street = addr.street
                    address.city = addr.city

                    if (addr.position && addr.position.lat && addr.position.lng) {
                        address.position = addr.position

                        this.setState({address})
                    }
                }
            })
        }
    }

    render() {
        const {edit, address} = this.state
        const {label, disabled} = this.props

        return (
            edit ?
                <div>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', flex: 1, marginLeft: 6}}>
                            <Input
                                style={{marginRight: 6}}
                                label='Postcode'
                                info={address.postalCode && address.showWarning && 'Dit adres staat niet in onze database, controleer of dit adres geldig is.'}
                                infoIcon='mdi mdi-alert'
                                value={address.postalCode}
                                onChange={this.setPostalCode.bind(this)}
                            />

                            <Input
                                style={{marginLeft: 6, marginRight: 6}}
                                label='Huisnummer'
                                value={address.nr}
                                onChange={this.setNr.bind(this)}
                            />

                            <Input
                                style={{marginLeft: 6}}
                                label='Toevoeging'
                                value={address.addition}
                                onChange={this.setAddition.bind(this)}
                            />

                            <IconButton onClick={() => {
                                const {address} = this.state

                                if (address.position) {
                                    this.setState({edit: false})
                                    this.props.onChange(address)
                                }
                            }}
                            >
                                <i className='mdi mdi-check'/>
                            </IconButton>

                        </div>
                    </div>

                    {address.street &&
                        <P style={{color: Colors.buttonSolid, marginLeft: 12, marginTop: -10, marginBottom: 12}}>{`${address.street}, ${address.city}`}</P>
                    }
                </div> :
                <div style={{display: 'flex', alignItems: 'center', minHeight: 40}}>
                    <S2 style={{marginLeft: 12}}>{label}</S2>
                    <P ellipsis style={{flex: 1, marginLeft: 6}}>{`${address.street} ${address.nr}${address.addition}, ${address.postalCode} ${address.city}`}</P>
                    {!disabled &&
                        <IconButton style={{marginRight: 12}} onClick={() => this.setState({edit: true})}>
                            <i className='mdi mdi-pencil'/>
                        </IconButton>
                    }
                </div>

        )
    }
}

export default (PostalCodeAutofill)
