// @ts-check
import moment from 'moment'
import time from '../../../server/utils/time.js'
import dbCodes from '../../../server/dbCodes.js'

/**
 * @param {Reseller} [reseller]
 * @returns {Order} order
 */
export default (reseller) => {
    /**
     * @type {Order}
     */
    const order = {
        date: time.today(),
        routeId: '',
        customer: '',
        contact: '',
        email: '',
        phone: '',

        customerAddress: {
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: ''
        },
        addresses: [{
            type: 'pickup',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                moment().format('HH:mm'),
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                moment().format('HH:mm')
        },
        {
            type: 'delivery',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                moment().format('HH:mm'),
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                moment().format('HH:mm'),
            gpsRequired: false,
            signatureRequired: false,
            signatureNeighbors: false,
            statedAddressOnly: false,
            nameRequired: false,
            scanRequired: false,
            imageRequired: false,
            mailboxDelivery: false,
            minimumAge: false,
            idCheck: false
        }],
        colli: [],
        addressOrder: 'fixed',

        nrOfDeliveries: 0,
        autoRetour: false,
        nrOfDaysBeforeRetour: 7,
        retourAddress: {
            type: 'delivery',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                '09:00',
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                '17:00',
            gpsRequired: false,
            signatureRequired: false,
            signatureNeighbors: false,
            statedAddressOnly: false,
            nameRequired: false,
            scanRequired: false,
            imageRequired: false,
            mailboxDelivery: false,
            minimumAge: false,
            idCheck: false
        },

        monitorTemperature: false,
        sensorId: '',

        priceTableId: '',
        price: '0,00',
        feeIds: [],
        fees: [],
        productCodes: [],
        invoiceDate: time.today(),
        invoicedAmount: '',
        dontInvoice: false,
        checked: false,
        invoiced: false,
        reference: '',

        notes: '',

        history: [],
        status: dbCodes.status.aangemaakt(),
        trackTrace: '',
        noTrackTrace: false,
        noEmailNotifications: false,

        additionalProps: {}

    }


    return JSON.parse(JSON.stringify(order))
}
