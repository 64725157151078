import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import aSync from 'async'
import _ from 'underscore'
import JSZip from 'jszip'
const zip = new JSZip()
import FileSaver from 'file-saver'

import CustomerStore from '../../stores/CustomerStore.js'
import InvoiceStore from '../../stores/InvoiceStore.js'
import InvoiceActions from '../../actions/InvoiceActions.js'

import InvoiceModal from '../../components/invoices/InvoiceModal.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import {Button, Colors, IconButton, Panel, Popup} from '../../components/UI/index.js'
import filter from '../../utils/filter.js'


class Invoices extends Reflux.Component {
    constructor(props) {
        super(props)
        this.stores = [InvoiceStore, CustomerStore]
    }

    componentDidMount() {
        const {reseller} = this.props
        InvoiceActions.get(true)

        if (this.props.location?.state?.invoiceId) {
            this.onClickViewInvoice(this.props.location?.state?.invoiceId)
        }

        document.title = `Facturatie • ${reseller.settings.accountName || reseller.name}`

        window.history.replaceState({}, '')
    }

    columns() {
        return [
            {title: 'Datum', visible: true, width: 120, key: 'date', render: (invoice) => {
                return invoice.invoiced ? moment(invoice.date).format('DD-MM-YYYY') : ''
            }},
            {title: 'Factuurnummer', visible: true, width: 200, key: 'invoiceNumber'},
            {title: 'Klant', visible: true, flex: 1, key: 'customer', sort: (invoice) => {
                return this.state.customers[invoice.customer] ? this.state.customers[invoice.customer].name : ''
            }, render: (invoice) => {
                return this.state.customers[invoice.customer] ? this.state.customers[invoice.customer].name : ''
            }},
            {title: 'Omschrijving', visible: true, flex: 1, key: 'description'},
            {title: 'Totaal', visible: true, width: 120, key: 'price', render: (invoice) => {
                return `€ ${invoice.price}`
            }},
            {title: 'Notities', visible: true, flex: 1, key: 'notes'},
            {title: 'Status', visible: true, width: 150, key: 'invoiced', render: (invoice) => {
                return invoice.invoiced ? 'Gefactureerd' : 'Concept'
            }},

            {title: '', visible: true, showOnHover: true, key: '', width: 60, onClick: () => {}, render: (invoice) => {
                return (
                    <IconButton onClick={this.onClickViewInvoice.bind(this, invoice.invoiced ? invoice._id : invoice)}>
                        <i className="mdi mdi-eye"/>
                    </IconButton>
                )
            }}
        ]
    }

    onChangeDates(startDate, endDate) {
        InvoiceActions.setDates(startDate, endDate)
    }

    onChangeQueries(invoiceQueries) {
        this.table.selectPage(1)
        InvoiceActions.setQueries(invoiceQueries)
    }

    onChangeTable(selectedInvoices) {
        InvoiceActions.setSelected(selectedInvoices)
    }

    onClickViewInvoice(id) {
        this.modal.open(id)
    }

    onClickSendInvoice() {
        const {reseller} = this.props
        const {selectedInvoices, customers} = this.state

        const invoices = _.filter(this.state.invoices, (invoice) => {
            return !invoice.invoiced && selectedInvoices.indexOf(invoice._id) > -1
        })


        this.popup.open('Facturen aanmaken', `Facturen worden aangemaakt${reseller.settings.administration.api ? ' en verstuurd naar het gekoppelde boekhoudsysteem' : ''}, hierna kunnen ze worden gedownload.`, () => {
            let success = 0
            const errors = []
            InvoiceActions.setSelected([])


            this.popup.setProgress(0, invoices.length)

            aSync.eachSeries(invoices, (invoice, next) => {
                InvoiceActions.send(invoice, (err) => {
                    if (err) {
                        const customer = _.findWhere(customers, {_id: invoice.customer})
                        errors.push(`${customer ? customer.name : invoice.customer} | ${err}`)
                    } else {
                        success += 1
                    }
                    this.popup.setProgress(success + errors.length, invoices.length)

                    next()
                })
            }, () => {
                this.popup.setMessage(`${success} ${success === 1 ? 'factuur' : 'facturen'} aangemaakt.`)
                this.popup.setErrors(errors)
            })
        })
    }

    onClickRemoveInvoice() {
        const {selectedInvoices} = this.state
        const {reseller} = this.props

        const invoices = _.filter(this.state.invoices, (invoice) => {
            return invoice.invoiced && selectedInvoices.indexOf(invoice._id) > -1
        })

        this.popup.open('Facturen verwijderen', `Verwijderde facturen worden weer zichtbaar als concept en kunnen opnieuw worden aangemaakt. ${reseller.settings.administration.api ? `Facturen worden niet verwijderd in ${reseller.settings.administration.api}` : ''}`, () => {
            InvoiceActions.setSelected([])

            aSync.eachSeries(invoices, (invoice, next) => {
                InvoiceActions.remove(invoice, (err) => {
                    if (err) {
                        this.popup.setError(err)
                    } else {
                        next()
                    }
                })
            }, () => {
                this.popup.close()
            })
        })
    }

    onClickExport() {
        const {selectedInvoices} = this.state

        this.setState({exportLoading: true})
        InvoiceActions.export(selectedInvoices, (err, response) => {
            if (err) {
                this.popup.open('Foutmelding', '', () => {})
                this.popup.setError(err)
            } else {
                if (response.errors.length) {
                    this.popup.open('Foutmeldingen', '', () => {})
                    this.popup.setErrors(response.errors)
                }
                const blob = new Blob([response.xml], {type: 'data:application/xml,charset=utf-8'})
                FileSaver.saveAs(blob, 'Administratie_export.xml')
            }

            this.setState({exportLoading: false})
        })
    }

    onClickDownloadAttachment() {
        const {selectedInvoices, invoices} = this.state

        const selected = _.filter(invoices, (invoice) => {
            return selectedInvoices.indexOf(invoice._id) > -1 && invoice.invoiced
        })

        if (selected.length) {
            this.setState({pdfLoading: true})

            const responses = []
            const usedCustomers = {}

            aSync.eachSeries(selected, (invoice, next) => {
                InvoiceActions.toPDF(invoice, (err, response) => {
                    if (!err) {
                        responses.push(response)
                    }
                    next()
                })
            }, () => {
                responses.map((response) => {
                    usedCustomers[response.customer] = usedCustomers[response.customer] || 0
                    const count = usedCustomers[response.customer]

                    zip.file(`${response.customer}${count > 0 ? `(${count})` : ''}.pdf`, response.pdf, {base64: true})

                    usedCustomers[response.customer] += 1
                })

                zip.generateAsync({type: 'base64'}).then((base64) => {
                    this.setState({pdfLoading: false})
                    window.location = `data:application/zip;base64,${base64}`
                })
            })
        }
    }

    render() {
        const {selectedInvoices, invoicesLoading, startDate, endDate, invoiceQueries, pdfLoading, exportLoading, customers} = this.state
        const {history, reseller} = this.props
        const invoices = filter.invoices(this.state.invoices, invoiceQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        onDateChange={this.onChangeDates.bind(this)}
                        startDate={startDate}
                        endDate={endDate}
                        queries={invoiceQueries}
                    />

                    {selectedInvoices.length > 0 &&
                        <>
                            <Button
                                variant='outline-white'
                                onClick={this.onClickSendInvoice.bind(this, null)}
                            >
                                Facturen aanmaken
                            </Button>

                            <Button
                                variant='outline-white'
                                loading={pdfLoading}
                                onClick={this.onClickDownloadAttachment.bind(this, null)}
                                tooltip='Factuur bijlages downloaden'
                            >
                                <i className="mdi mdi-download"/>
                            </Button>

                            {reseller.settings.administration.xmlExport &&
                                <Button
                                    variant='outline-white'
                                    loading={exportLoading}
                                    onClick={this.onClickExport.bind(this, null)}
                                    tooltip='XML export'
                                >
                                    <i className="mdi mdi-file-export"/>
                                </Button>
                            }


                            <Button
                                variant='outline-white'
                                onClick={this.onClickRemoveInvoice.bind(this)}
                            >
                                <i className="mdi mdi-delete"/>
                            </Button>
                        </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='invoices'
                        columns={this.columns()}
                        rows={invoices}
                        selectedRows={selectedInvoices}
                        loading={invoicesLoading}
                        onChange={this.onChangeTable.bind(this)}
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <InvoiceModal
                    history={history}
                    customers={customers}
                    onClickSendInvoice={InvoiceActions.send}
                    toPDF={InvoiceActions.toPDF}
                    ref={(modal) => this.modal = modal}
                />
                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Invoices
