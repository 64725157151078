import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import InvoiceActions from '../actions/InvoiceActions.js'

export default class InvoiceStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            invoiceQueries: [],
            invoices: [],
            selectedInvoices: [],
            invoicesLoading: true
        }
        this.listenables = InvoiceActions

        socket.on('updateInvoices', () => {
            InvoiceActions.get(true)
        })
    }

    onSetDates(startDate, endDate) {
        this.setState({startDate, endDate, selectedInvoices: []})
        InvoiceActions.get(true)
    }

    onSetQueries(invoiceQueries) {
        this.setState({invoiceQueries, selectedInvoices: []})
    }

    onSetSelected(selectedInvoices) {
        this.setState({selectedInvoices})
    }


    onGet(loading) {
        const {startDate, endDate, selectedInvoices} = this.state
        this.setState({invoicesLoading: !!loading})

        socket.emit('invoices.get', sessionStorage.token, startDate, endDate, (err, invoices) => {
            const ids = _.pluck(invoices, '_id')

            this.setState({
                invoices,
                selectedInvoices: _.filter(selectedInvoices, (id) => ids.indexOf(id) > -1),
                invoicesLoading: false
            })
        })
    }

    onGetOne(id, callback) {
        socket.emit('invoices.getOne', sessionStorage.token, id, callback)
    }

    onSend(invoice, callback) {
        socket.emit('invoices.send', sessionStorage.token, invoice, callback)
    }

    onRemove(invoice, callback) {
        socket.emit('invoices.remove', sessionStorage.token, invoice, callback)
    }

    onExport(selectedInvoices, callback) {
        socket.emit('invoices.export', sessionStorage.token, selectedInvoices, callback)
    }


    onToPDF(invoices, callback) {
        socket.emit('invoices.ToPDF', sessionStorage.token, invoices, callback)
    }

    onEditNotes(id, notes, callback) {
        socket.emit('invoices.editNotes', sessionStorage.token, id, notes, callback)
    }
}
