import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import csvExport from '../../utils/csvExport.js'
import filter from '../../utils/filter.js'


import CustomerStore from '../../stores/CustomerStore.js'
import CustomerActions from '../../actions/CustomerActions.js'
import AddressStore from '../../stores/AddressStore.js'
import AddressActions from '../../actions/AddressActions.js'
import ParcelStore from '../../stores/ParcelStore.js'
import UserStore from '../../stores/UserStore.js'
import LoginActions from '../../actions/LoginActions.js'


import CustomerModal from '../../components/customers/CustomerModal.js'
import ImportCustomers from '../../components/customers/ImportCustomers.js'
import {Button, IconButton, Colors, Popup, Panel, MfaPopup, P, Toggle} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'


class Customers extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [CustomerStore, ParcelStore, UserStore, AddressStore]

        this.state = {
            showArchived: false
        }
    }

    componentDidMount() {
        const {reseller} = this.props

        document.title = `Klanten • ${reseller.settings.accountName || reseller.name}`
    }

    columns() {
        const columns = [
            {title: 'Sync', visible: true, key: 'synced', width: 50, onClick: () => {}, render: (customer) => {
                return (
                    <i style={{fontSize: 12, color: customer.synced ? Colors.successBright: Colors.warningBright}} className="mdi mdi-circle"/>
                )
            }},
            {title: 'Debiteurnummer', visible: true, key: 'debtorCode', width: 150},
            {title: 'Naam', visible: true, key: 'name', flex: 1},
            {title: 'Adres', visible: true, flex: 1, render: (customer) => {
                const address = customer.address

                return `${address.street} ${address.nr}${address.addition}`
            }},
            {title: 'Postcode', visible: true, key: 'address.postalCode', width: 120},
            {title: 'Plaats', visible: true, key: 'address.city', flex: 1},
            {title: 'Telefoon', visible: true, key: 'phone', width: 150},
            {title: 'Emailadres', visible: true, key: 'email', flex: 1},
            {title: 'Tarieftabel Nederland', visible: false, key: 'settings.parcels.priceTable', flex: 1, sort: (customer) => {
                return customer.settings.parcels.priceTable || 'Standaard'
            }, render: (customer) => {
                return customer.settings.parcels.priceTable || 'Standaard'
            }},
            {title: 'Tarieftabel buitenland', visible: false, key: 'settings.parcels.priceTableAbroad', flex: 1, sort: (customer) => {
                return customer.settings.parcels.priceTableAbroad || customer.settings.parcels.priceTable || 'Standaard'
            }, render: (customer) => {
                return customer.settings.parcels.priceTableAbroad || customer.settings.parcels.priceTable || 'Standaard'
            }},
            {title: 'Laatste factuur', visible: false, key: 'lastInvoice', flex: 1, render: (customer) => {
                return customer.lastInvoice ? moment(customer.lastInvoice).format('DD-MM-YYYY') : ''
            }},
            {title: 'Laatst actief', visible: true, key: 'lastActive', flex: 1, render: (customer) => {
                return customer.lastActive ? moment(customer.lastActive).format('DD-MM-YYYY HH:mm') : ''
            }},
            {
                title: 'Prijs ophaalrit', visible: false, width: 100, key: 'settings.parcels.autoPickupPrice',
                sort: (customer) => {
                    return customer.settings.parcels.autoPickupOrder && customer.settings.parcels.autoPickupPrice ? `€ ${customer.settings.parcels.autoPickupPrice}` : ''
                },
                render: (customer) => {
                    return customer.settings.parcels.autoPickupOrder && customer.settings.parcels.autoPickupPrice ? `€ ${customer.settings.parcels.autoPickupPrice}` : ''
                }},
            {title: 'Bijlage', visible: false, key: 'attachments', flex: 1, onClick: () => {}, render: (customer) => {
                const address = customer.address
                return (
                    <P
                        onClick={() => {
                            const attachment = address.attachments?.[0] || {}
                            const openUrl = (file) => {
                                const raw = window.atob(file)
                                const rawLength = raw.length
                                const array = new Uint8Array(new ArrayBuffer(rawLength))

                                for (let i = 0; i < rawLength; i++) {
                                    array[i] = raw.charCodeAt(i)
                                }

                                const blob = new Blob([array], {type: attachment.type})

                                const blobUrl = URL.createObjectURL(blob)

                                window.open(blobUrl)
                            }
                            if (attachment.fileId) {
                                AddressActions.downloadAttachment(attachment.fileId, (err, file) => {
                                    if (err) {
                                        console.log(err)
                                    } else {
                                        openUrl(file)
                                    }
                                })
                            } else if (attachment.data) {
                                window.open(attachment.data)
                            }
                        }}
                        ellipsis
                    >
                        {address.attachments?.map((a) => a.fileName).join(',')}
                    </P>
                )
            }},
            {title: 'Login', visible: false, key: 'login', width: 100, render: (customer) => {
                return customer.login ? 'Ja' : 'Nee'
            }},
            {title: 'Gearchiveerd', visible: false, key: 'isArchived', width: 100, render: (customer) => {
                return customer.isArchived ? 'Ja' : 'Nee'
            }},
            {title: '', visible: true, showOnHover: true, key: '', width: 60, onClick: () => {}, render: (customer) => {
                return (
                    <IconButton onClick={this.onClickEdit.bind(this, customer)}>
                        <i className="mdi mdi-pencil"/>
                    </IconButton>
                )
            }}
        ]

        return columns
    }

    onChangeQueries(customerQueries) {
        this.table.selectPage(1)
        CustomerActions.setQueries(customerQueries)
    }

    onChangeTable(selectedCustomers) {
        CustomerActions.setSelected(selectedCustomers)
    }

    onClickNew() {
        this.modal.open()
        CustomerActions.setSelected([])
    }

    onClickEdit(customer) {
        this.modal.open(customer)
    }

    onClickToggleArchive(_event, deArchive = false) {
        const {selectedCustomers, customers} = this.state
        const {reseller} = this.props

        if (deArchive) {
            const customersToBeDeArchived = []

            selectedCustomers.forEach((id) => {
                if (customers[id]?.isArchived) {
                    customersToBeDeArchived.push(id)
                }
            })

            this.popup.open('Klant dearchiveren', `${customersToBeDeArchived.length} ${customersToBeDeArchived.length === 1 ? 'klant wordt' : 'klanten worden'} weer actief gemaakt voor gebruik.`, () => {
                CustomerActions.setSelected([])

                CustomerActions.toggleArchive(customersToBeDeArchived, (err) => {
                    if (err) {
                        this.popup.setError(err)
                    } else {
                        this.popup.close()
                    }
                })
            })
        } else {
            const customersToBeArchived = []

            selectedCustomers.forEach((id) => {
                if (!customers[id]?.isArchived) {
                    customersToBeArchived.push(id)
                }
            })

            this.popup.open('Klant archiveren', `${customersToBeArchived.length} ${customersToBeArchived.length === 1 ? 'klant wordt' : 'klanten worden'} gearchiveerd.${reseller.settings.administration.api ? `\nKlanten worden niet gearchiveerd in ${reseller.settings.administration.api}.` : ''}`, () => {
                CustomerActions.setSelected([])

                CustomerActions.toggleArchive(customersToBeArchived, (err) => {
                    if (err) {
                        this.popup.setError(err)
                    } else {
                        this.popup.close()
                    }
                })
            })
        }
    }

    onClickSync() {
        this.popup.open('Synchroniseer klanten', 'Dit kan enkele minuten duren.', () => {
            CustomerActions.setSelected([])

            CustomerActions.sync((err, res) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.setMessage(`${res.new} nieuwe ${res.new === 1 ? 'klant' : 'klanten'}, ${res.synced} ${res.synced === 1 ? 'klant' : 'klanten'} gesynchroniseerd.`)

                    this.popup.setErrors(res.errors)
                }

                AddressActions.get()
            })
        })
    }

    onClickImport() {
        this.importModal.open()
        CustomerActions.setSelected([])
    }

    onClickExport() {
        const {customers, selectedCustomers} = this.state
        const exportCustomers = []

        selectedCustomers.map((id) => {
            const customer = customers[id]
            if (customer) {
                exportCustomers.push(customer)
            }
        })

        csvExport.customers(exportCustomers)
        CustomerActions.setSelected([])
    }

    onClickLogin() {
        const {customers, selectedCustomers} = this.state

        const customer = customers[selectedCustomers[0]]
        if (customer) {
            LoginActions.loginAsCustomer(customer, (err, loginWithMfa) => {
                if (err) {
                    this.popup.open('Foutmelding', '', () => {})
                    this.popup.setError(err)
                }
                if (typeof loginWithMfa === 'function') {
                    this.popup.open('Deze klant heeft tweestapsverificatie geactiveerd.', 'Deze klant heeft tweestapsverificatie geactiveerd. Om in te loggen heb je de verificatie code nodig van de klant, weet je zeker dat je wilt doorgaan?', () => {
                        loginWithMfa(this.mfaPopup.open((code) => {
                            LoginActions.verify2FACode(code, (err) => {
                                if (err) {
                                    this.mfaPopup.setError(err)
                                } else {
                                    this.mfaPopup.close()
                                    localStorage.loginAsCustomerToken = sessionStorage.customerToken
                                    window.open('/klant')
                                    setTimeout(() => delete sessionStorage.customerToken, 300)
                                }
                            })
                        }, customer.mfaMethod))
                    })
                }
            })
        }
    }

    onClickSendWelcomeEmail() {
        const {selectedCustomers, customers} = this.state

        const customersToSend = []

        selectedCustomers.map((id) => {
            const customer = customers[id]

            if (customer.login && customer.loginEmail) {
                customersToSend.push(customer)
            }
        })

        this.popup.open('Verstuur welkomstmail', `Weet je zeker dat je ${customersToSend.length} welkomstmail${customersToSend.length === 1 ? '' : 's'} wilt versturen?`, async () => {
            const errors = []

            for (const customer of customersToSend) {
                await new Promise((resolve) => {
                    LoginActions.sendCustomerWelcomeMail(customer, (err) => {
                        if (err) {
                            errors.push(err)
                        }

                        resolve()
                    })
                })
            }

            if (errors.length > 0) {
                this.popup.setError(errors)
            } else {
                this.popup.setMessage('Welkomstmails zijn verstuurd.')
            }
        })
    }

    render() {
        const {selectedCustomers, customersLoading, customerQueries, carriers, showArchived} = this.state
        let {customers: stateCustomers} = this.state
        const {reseller, user} = this.props

        stateCustomers = _.pick(stateCustomers, (customer) => {
            return showArchived || !customer.isArchived
        })

        const customers = filter.customers(_.values(stateCustomers), customerQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns().filter((column) => column.key !== 'isArchived')}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        queries={customerQueries}
                    />

                    {(!reseller.settings.administration.api || !reseller.settings.administration.noNewCustomers) &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickNew.bind(this)}
                        >Nieuwe Klant </Button>
                    }

                    {reseller.settings.administration.api &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickSync.bind(this)}
                        >
                            <i className="mdi mdi-refresh"/>
                        </Button>
                    }

                    {(!reseller.settings.administration.api || !reseller.settings.administration.noNewCustomers) &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickImport.bind(this)}
                        >
                            <i className="mdi mdi-file-import"/>
                        </Button>
                    }


                    {selectedCustomers.length > 0 &&
                        <>
                            <Button
                                variant='outline-white'
                                onClick={this.onClickExport.bind(this)}
                            >
                                <i className="mdi mdi-file-export"/>
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickToggleArchive.bind(this)}
                                tooltip='Archiveren'
                            >
                                <i className="mdi mdi-archive"/>
                            </Button>

                            {showArchived &&
                                <Button
                                    variant='outline-white'
                                    onClick={this.onClickToggleArchive.bind(this, true)}
                                    tooltip='Dearchiveren'
                                >
                                    <i className="mdi mdi-archive-off"/>
                                </Button>
                            }

                            <Button
                                variant='outline-white'
                                onClick={this.onClickSendWelcomeEmail.bind(this)}
                            >
                                Verstuur welkomstmail
                            </Button>
                        </>
                    }

                    {selectedCustomers.length === 1 &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickLogin.bind(this)}
                        >
                            <i className="mdi mdi-login"/>
                        </Button>
                    }

                    <Toggle
                        style={{width: 'fit-content', marginLeft: 'auto'}}
                        label='Toon gearchiveerde klanten'
                        checked={showArchived}
                        onChange={(event) => this.setState({showArchived: event.target.checked})}
                    />

                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='customers'
                        columns={this.columns()}
                        rows={customers}
                        selectedRows={selectedCustomers}
                        loading={customersLoading}
                        onChange={this.onChangeTable.bind(this)}
                        sortKey='name'
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <CustomerModal
                    ref={(modal) => this.modal = modal}
                    reseller={reseller}
                    user={user}
                    carriers={carriers}
                />

                <ImportCustomers
                    ref={(modal) => this.importModal = modal}
                />

                <Popup ref={(modal) => this.popup = modal}/>
                <MfaPopup info='Deze klant heeft tweestapsverificatie geactiveerd. Neem contact op met de klant om de inlogcode te verkrijgen.' confirmButtonText='Bevestig' ref={(ref) => this.mfaPopup = ref}/>
            </div>
        )
    }
}

export default (Customers)
