import React from 'react'

import _ from 'underscore'

import AddressActions from '../../actions/AddressActions.js'

import Countries from '../../utils/Countries.js'
import LocationPicker from '../map/LocationPicker.js'
import {Check, Select, Input, Card, Colors, Row, Column, AddressAttachment} from '../UI/index.js'
import guid from '../../../utils/guid.js'

const regPostal = /^[0-9]{4} [A-Z]{2}$/

class Address extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            suggestions: [],
            suggested: false,
            validation: {}
        }

        this.focus = this.focus.bind(this)
    }

    componentDidUpdate(prevProps) {
        const {address, noAutoComplete} = this.props

        if (!noAutoComplete && (prevProps.address.postalCode !== address.postalCode || prevProps.address.nr !== address.nr || prevProps.address.addition !== address.addition)) {
            this.autocomplete(address)
        }
    }

    setName(event) {
        let {validation, suggested} = this.state
        validation.name = null
        this.setState({validation})

        const {addressBook = []} = this.props
        const address = JSON.parse(JSON.stringify(this.props.address))

        const suggestions = event.target.value.length < 1 ? [] : addressBook.filter((value) => {
            return value.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
        })

        address.name = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value

        const suggestion = _.find(suggestions, (suggestion) => {
            return suggestion.name === event.target.value
        })

        if (suggestion) {
            suggested = true
            address.attention = suggestion.attention || ''
            address.street = suggestion.street || ''
            address.nr = suggestion.nr || ''
            address.addition = suggestion.addition || ''
            address.street2 = suggestion.street2 || ''
            address.postalCode = suggestion.postalCode || ''
            address.city = suggestion.city || ''
            address.country = suggestion.country || 'NL'
            address.isBusiness = !!suggestion.isBusiness
            address.email = suggestion.email || address.email || ''
            address.phone = suggestion.phone || address.phone || ''
            address.instructions = suggestion.instructions || address.instructions || ''
            if (!address.overridePosition) {
                if (suggestion.position) {
                    address.position = suggestion.position
                } else {
                    delete address.position
                }
            }

            typeof this.props.onConfirm === 'function' && this.props.onConfirm()
        } else if (suggested) {
            suggested = false
            address.attention = ''
            address.street = ''
            address.nr = ''
            address.addition = ''
            address.street2 = ''
            address.postalCode = ''
            address.city = ''
            address.country = 'NL'
            address.isBusiness = false
            address.email = ''
            address.phone = ''
            address.instructions = ''
            delete address.position
        }

        this.setState({suggestions: suggestions.splice(0, 9), suggested})
        this.change(address)
    }

    setAttention(event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address.attention = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        this.change(address)
    }

    setPostalCode(event) {
        const {validation} = this.state
        validation.postalCode = null
        this.setState({validation})

        const address = JSON.parse(JSON.stringify(this.props.address))
        let postalCode = event.target.value.toUpperCase().replace(/  +/g, ' ')

        if (/^[0-9]{4}[A-Z]{2}$/.test(postalCode)) {
            postalCode = `${postalCode.substr(0, 4)} ${postalCode.substr(4, 2)}`
        }

        if (/^[0-9]{4} [A-Z]{2} $/.test(postalCode)) {
            postalCode = postalCode.trim()
        }

        address.postalCode = postalCode
        !address.overridePosition && delete address.position && delete address.showWarning
        this.change(address)
    }

    setNr(event) {
        const {validation} = this.state
        validation.nr = null
        this.setState({validation})

        const address = JSON.parse(JSON.stringify(this.props.address))
        address.nr = event.target.value.toUpperCase()
        !address.overridePosition && delete address.position && delete address.showWarning
        this.change(address)
    }

    setAddition(event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address.addition = event.target.value.toUpperCase()

        if (/[0-9]/.test(address.addition[0])) {
            address.addition = `-${address.addition}`
        } else if (address.addition[1] && /[-]/.test(address.addition[0]) && /[^0-9]/.test(address.addition[1])) {
            address.addition = address.addition.substr(1)
        }

        this.change(address)
    }

    setStreet(event) {
        const {validation} = this.state
        validation.street = null
        this.setState({validation})

        const address = JSON.parse(JSON.stringify(this.props.address))
        address.street = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value

        this.change(address)
    }

    setStreet2(event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address.street2 = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        this.change(address)
    }

    setCity(event) {
        const {validation} = this.state
        validation.city = null
        this.setState({validation})

        const address = JSON.parse(JSON.stringify(this.props.address))
        address.city = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        this.change(address)
    }

    changeState(event) {
        const {validation} = this.state
        validation.state = null
        this.setState({validation})

        const address = JSON.parse(JSON.stringify(this.props.address))
        address.state = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value
        this.change(address)
    }

    setCountry(event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address.country = event.target.value
        this.change(address)
    }

    autocomplete(address) {
        return new Promise((resolve) => {
            if (address.country === 'NL' && /^[0-9]{4} [A-Z]{2}$/.test(address.postalCode) && address.nr) {
                this.lastCallbackId = guid.generate()

                AddressActions.autocomplete(address, this.lastCallbackId, (err, addr, callbackId) => {
                    if (err || callbackId !== this.lastCallbackId) {
                        return
                    }
                    // Important
                    const address = JSON.parse(JSON.stringify(this.props.address))

                    if (addr) {
                        if (addr.street) {
                            address.street = addr.street || ''
                        }

                        if (addr.city) {
                            address.city = addr.city || ''
                        }

                        address.isBusiness = !!addr.isBusiness

                        if (!address.overridePosition && addr.position && addr.position.lat && addr.position.lng) {
                            address.position = addr.position
                        }

                        if (typeof addr.showWarning !== 'undefined') {
                            address.showWarning = addr.showWarning
                        }
                    }

                    this.change(address)
                    resolve(address)
                })
            }
            resolve('')
        })
    }

    onChangeValue(key, event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address[key] = event.target.value
        this.change(address)
    }

    onChangeChecked(key, event) {
        const address = JSON.parse(JSON.stringify(this.props.address))
        address[key] = event.target.checked
        this.change(address)

        if (key === 'overridePosition' && !event.target.checked) {
            this.autocomplete(address)
        }
    }

    change(address) {
        this.props.onChange(address)
    }

    validate() {
        const {id = '', allowEmptyName} = this.props
        const address = JSON.parse(JSON.stringify(this.props.address))
        const validation = {}
        let valid = true
        let focusId = ''


        if (address.country === 'US' && !address.state) {
            focusId = `state${id}`
            validation.state = true
            valid = false
        }

        if (!address.city) {
            focusId = `city${id}`
            validation.city = true
            valid = false
        }

        if (!address.street) {
            focusId = `street${id}`
            validation.street = true
            valid = false
        }

        if (address.country === 'NL' && !address.nr) {
            focusId = `nr${id}`
            validation.nr = true
            valid = false
        }

        if (!address.postalCode || (address.country === 'NL' && !regPostal.test(address.postalCode))) {
            focusId = `postalCode${id}`
            validation.postalCode = true
            valid = false
        }

        if (!address.name && !allowEmptyName) {
            focusId = `name${id}`
            validation.name = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})
        return valid
    }

    focus() {
        const {id = ''} = this.props
        document.getElementById(`name${id}`).focus()
    }

    render() {
        const {suggestions, validation} = this.state
        const {id = '', address, noTAV, noCountry, state, email, emailLabel, phone, phoneLabel, businessCheckbox, overridePosition, allowAttachments, showInstructions, saveCheckbox, disabled, readOnly} = this.props

        const countries = [
            {value: 'NL', title: 'Nederland', bold: true},
            {value: 'BE', title: 'België', bold: true},
            {value: 'DE', title: 'Duitsland', bold: true},
            {value: 'FR', title: 'Frankrijk', bold: true},
            {value: 'IT', title: 'Italië', bold: true},
            {value: 'ES', title: 'Spanje', bold: true},
            {value: 'GB', title: 'Verenigd Koninkrijk', bold: true},
            {value: 'US', title: 'Verenigde Staten', bold: true}
        ]

        {Countries.map((country) => {
            countries.push({value: country.code2, title: country.name})
        })}

        return (
            <Card style={{background: Colors.grey20}}>
                <Row style={{flexWrap: 'wrap', marginTop: 12}}>
                    <Column>
                        <Input
                            id={`name${id}`}
                            style={{minWidth: 200}}
                            label='Naam'
                            disabled={disabled}
                            readOnly={readOnly}
                            suggestions={_.pluck(suggestions, 'name')}
                            value={address.name}
                            isInvalid={validation.name}
                            onChange={this.setName.bind(this)}
                            autocomplete="off"
                        />
                    </Column>

                    {!noTAV &&
                    <Column>
                        <Input
                            style={{minWidth: 200}}
                            label='Ter attentie van'
                            value={address.attention}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={this.setAttention.bind(this)}
                        />
                    </Column>
                    }
                </Row>

                <Row style={{flexWrap: 'wrap'}}>
                    <Column>
                        <Input
                            id={`postalCode${id}`}
                            style={{minWidth: 100}}
                            label='Postcode'
                            info={address.postalCode && address.country === 'NL' && address.showWarning && 'Dit adres staat niet in onze database, controleer of dit adres geldig is.'}
                            infoIcon='mdi mdi-map-marker-alert'
                            value={address.postalCode}
                            disabled={disabled}
                            readOnly={readOnly}
                            isInvalid={validation.postalCode}
                            onChange={this.setPostalCode.bind(this)}
                        />
                    </Column>

                    <Column style={{display: 'flex'}}>
                        <Input
                            id={`nr${id}`}
                            style={{minWidth: 100}}
                            label='Huisnummer'
                            value={address.nr}
                            disabled={disabled}
                            readOnly={readOnly}
                            isInvalid={validation.nr}
                            onChange={this.setNr.bind(this)}
                        />

                        <Input
                            style={{minWidth: 100}}
                            label='Toevoeging'
                            value={address.addition}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={this.setAddition.bind(this)}
                        />
                    </Column>
                </Row>

                <Row style={{flexWrap: 'wrap'}}>
                    <Column>
                        <Input
                            id={`street${id}`}
                            style={{minWidth: 200}}
                            label='Straat'
                            value={address.street || ''}
                            disabled={disabled}
                            readOnly={readOnly}
                            isInvalid={validation.street}
                            onChange={this.setStreet.bind(this)}
                        />
                    </Column>
                    <Column>
                        <Input
                            style={{minWidth: 200}}
                            label='2e adres regel'
                            value={address.street2}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={this.setStreet2.bind(this)}
                        />
                    </Column>
                </Row>

                <Row style={{flexWrap: 'wrap'}}>
                    <Column>
                        <Input
                            id={`city${id}`}
                            style={{minWidth: 200}}
                            label='Plaats'
                            value={address.city}
                            disabled={disabled}
                            readOnly={readOnly}
                            isInvalid={validation.city}
                            onChange={this.setCity.bind(this)}
                        />
                    </Column>

                    <Column>
                        <Select
                            style={{minWidth: 200}}
                            label='Land'
                            disabled={noCountry || disabled}
                            readOnly={readOnly}
                            value={noCountry ? (address.country || 'NL') : address.country}
                            options={countries}
                            onChange={this.setCountry.bind(this)}
                        />
                    </Column>
                </Row>

                {state && address.country === 'US' &&
                    <Row>
                        <Column>
                            <Input
                                id={`state${id}`}
                                style={{minWidth: 200}}
                                label='Staat'
                                value={address.state}
                                disabled={disabled}
                                readOnly={readOnly}
                                isInvalid={validation.state}
                                onChange={this.changeState.bind(this)}
                            />
                        </Column>
                        <Column/>
                    </Row>
                }

                <Row style={{flexWrap: 'wrap'}}>
                    {email &&
                        <Column>
                            <Input
                                style={{minWidth: 200}}
                                label={emailLabel || 'Emailadres'}
                                value={address.email}
                                disabled={disabled}
                                readOnly={readOnly}
                                onChange={this.onChangeValue.bind(this, 'email')}
                            />
                        </Column>
                    }

                    {phone &&
                        <Column>
                            <Input
                                style={{minWidth: 200}}
                                label={phoneLabel || 'Telefoon'}
                                value={address.phone}
                                disabled={disabled}
                                readOnly={readOnly}
                                onChange={this.onChangeValue.bind(this, 'phone')}
                            />
                        </Column>
                    }
                </Row>

                {showInstructions &&
                    <Row>
                        <Column>
                            <Input
                                label='Instructies'
                                value={address.instructions}
                                disabled={disabled}
                                readOnly={readOnly}
                                onChange={this.onChangeValue.bind(this, 'instructions')}
                            />
                        </Column>
                    </Row>
                }

                <Row style={{flexWrap: 'wrap'}}>
                    {businessCheckbox &&
                        <Column>
                            <Check
                                style={{minWidth: 200}}
                                label="Bedrijfsadres"
                                checked={address.isBusiness}
                                disabled={disabled}
                                readOnly={readOnly}
                                onChange={this.onChangeChecked.bind(this, 'isBusiness')}
                            />
                        </Column>
                    }

                    {overridePosition &&
                        <Column>
                            <Check
                                style={{minWidth: 200}}
                                label="Aangepaste locatie"
                                checked={address.overridePosition}
                                disabled={disabled}
                                readOnly={readOnly}
                                onChange={this.onChangeChecked.bind(this, 'overridePosition')}
                            />
                        </Column>
                    }

                    {saveCheckbox && !disabled && !readOnly &&
                        <Column>
                            <Check
                                style={{minWidth: 200}}
                                label="Opslaan in adresboek"
                                checked={address.save}
                                onChange={this.onChangeChecked.bind(this, 'save')}
                            />
                        </Column>
                    }

                    {allowAttachments &&
                        <Column style={{display: 'block', marginLeft: 12}}>
                            <AddressAttachment
                                edit
                                onChange={this.change.bind(this)}
                                address={address}
                            />
                        </Column>
                    }
                </Row>

                {address.overridePosition &&
                    <LocationPicker
                        value={address?.position || {lat: 52.2076823, lng: 5.1584931}} //  If default value is not provided, locationpicker crashes
                        onChange={(position) => {
                            this.change({
                                ...address,
                                position
                            })
                        }}
                    />
                }
            </Card>
        )
    }
}

export default Address
