// @ts-check

import React, {useState, forwardRef, useImperativeHandle, useRef, useEffect} from 'react'
import {H4, IconButton, Input, Modal, Button, Alert} from '../../UI/index.js'

/**
 * @type {React.FC<{
 * onRemove: (carrierName: string, product: ParcelProducts, countryGroupIndex: number, categoryIndex: number, scaleIndex: number) => void;
 * onSubmit: (carrierName: string, product: ParcelProducts, countryGroupIndex: number, categoryIndex: number, scaleIndex: number, scale: ParcelPriceTableProductCountryGroup['priceScales'][0]) => void;}
 * & React.RefAttributes<{
 * open: (carrierName: string, product: ParcelProducts, countryGroupIndex: number, categoryIndex?: number, scaleIndex?: number, scale?: ParcelPriceTableProductCountryGroup['priceScales'][0]) => void;
 * close: () => void;
 * setWarnings: React.Dispatch<React.SetStateAction<string[]>>
 * }>
 * >} PriceScaleModal
 */
const PriceScaleModal = forwardRef(({onRemove, onSubmit}, ref) => {
    const [open, setOpen] = useState(false)
    /**
     * @type {ReturnType<typeof useState<ParcelPriceTableProductCountryGroup['priceScales'][0]>>}
     */
    const [scale, setScale] = useState()
    /**
     * @type {ReturnType<typeof useState<{carrierName: string; product: ParcelProducts; countryGroupIndex: number; categoryIndex: number; scaleIndex: number}>>}
     */
    const [scaleInfo, setScaleInfo] = useState()
    /**
     * @type {ReturnType<typeof useState<string[]>>}
     */
    const [warnings, setWarnings] = useState([])
    const inputRef = useRef(null)


    /**
     *
     * @param {string} carrierName
     * @param {ParcelProducts} product
     * @param {number} countryGroupIndex
     * @param {number} [categoryIndex]
     * @param {number} [scaleIndex]
     * @param {ParcelPriceTableProductCountryGroup['priceScales'][0]} [scale]
     */
    const openModal = (carrierName, product, countryGroupIndex, categoryIndex, scaleIndex, scale = {maxAmount: 0, price: ''}) => {
        setOpen(true)
        setScale(scale)
        setScaleInfo({
            carrierName,
            product,
            countryGroupIndex,
            categoryIndex,
            scaleIndex
        })
    }

    const closeModal = () => {
        setOpen(false)
        setScale(null)
        setScaleInfo(null)
    }

    useImperativeHandle(ref, () => ({
        open: openModal,
        close: closeModal,
        setWarnings
    }))

    useEffect(() => {
        if (open && inputRef.current) {
            inputRef.current.focus()
        }
    }, [open])

    return (
        <Modal style={{width: 400}} show={open} onClose={() => setOpen(false)}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <H4>Staffel</H4>

                <IconButton
                    onClick={() => setOpen(false)}
                    icon='mdi mdi-close'
                />
            </div>

            <div style={{display: 'flex', gap: 12}}>
                <Input
                    label='Maximaal aantal stuks'
                    value={scale?.maxAmount}
                    onChange={(event) => {
                        setScale({
                            ...scale,
                            maxAmount: parseInt(event.target.value)
                        })
                    }}
                    ref={(ref) => inputRef.current = ref}
                />
            </div>

            {warnings && warnings.length > 0 && warnings.map((warning, index) => (
                <Alert key={index} variant='warning'>{warning}</Alert>
            ))}

            <br/>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {typeof scaleInfo?.scaleIndex === 'number' && (
                    <Button
                        variant='text'
                        onClick={() => {
                            const {carrierName, product, countryGroupIndex, categoryIndex, scaleIndex} = scaleInfo
                            onRemove(carrierName, product, countryGroupIndex, categoryIndex, scaleIndex)
                        }}
                    >
                        Verwijder
                    </Button>
                )}
                <Button
                    variant='text'
                    onClick={() => {
                        const {carrierName, product, countryGroupIndex, categoryIndex, scaleIndex} = scaleInfo
                        onSubmit(carrierName, product, countryGroupIndex, categoryIndex, scaleIndex, scale)
                    }}
                >
                    Opslaan
                </Button>
            </div>
        </Modal>
    )
})

export default PriceScaleModal
