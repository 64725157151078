import React from 'react'

import {Colors, ToolTip, Spinner} from '../UI/index.js'

export default class Button extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    componentWillUnmount() {
        clearTimeout(this.clickedTimout)
    }

    onClick(event) {
        // event?.stopPropagation()

        // Prevent double click
        if (this.clicked) return
        this.clicked = true
        this.clickedTimout = setTimeout(() => this.clicked = false, 200)

        typeof this.props.onClick === 'function' && this.props.onClick(event)
    }

    render() {
        const {style={}, buttonStyle={}, type='button', color, nav, selected, variant='solid', icon, afterIcon, label, onMouseDown, children, disabled, loading, tooltip, tooltipPlacement} = this.props

        if (!nav) {
            let defaultStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 4,
                border: 'none',
                outline: 'none',
                fontSize: typeof children === 'string' ? 14 : 18,
                fontWeight: 500,
                letterSpacing: 0.25,
                cursor: disabled ? 'default' : 'pointer',
                height: variant === 'action' ? 'auto' : 36,
                width: '100%',
                paddingLeft: 12,
                paddingRight: 12
            }

            if (variant === 'solid') {
                defaultStyle = {
                    ...defaultStyle,
                    background: disabled ? Colors.textMedium : (color || Colors.buttonSolid),
                    color: color ? (Colors.isDarkBackground(color) ? Colors.white : Colors.black) : Colors.white

                }
            }

            if (variant === 'outline' || variant === 'outline-white') {
                defaultStyle = {
                    ...defaultStyle,
                    background: disabled ? Colors.backgroundSelected : selected ? Colors.backgroundSelected : variant === 'outline' ? Colors.backgroundNeutral: Colors.backgroundWhite,
                    color: disabled ? Colors.textMedium : Colors.textDark,
                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)'

                }
            }

            if (variant === 'text') {
                defaultStyle = {
                    ...defaultStyle,
                    background: selected ? Colors.backgroundSelected : 'transparent',
                    color: disabled ? Colors.textMedium : Colors.textDark

                }
            }

            return (
                <ToolTip text={tooltip} placement={tooltipPlacement} style={{marginLeft: 6, marginRight: 6, height: 36, width: !style.flex ? 'fit-content' : '', ...style}}>
                    <button
                        tabIndex="-1"
                        style={{...defaultStyle, ...buttonStyle}}
                        type={type}
                        disabled={disabled || loading}
                        onClick={disabled || loading ? null : this.onClick.bind(this)}
                        onMouseDown={loading ? null : onMouseDown}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                        onFocus={() => this.setState({focus: false})}
                        onBlur={() => this.setState({focus: false})}

                    >
                        {loading ?
                            <Spinner color={variant === 'solid' ? 'white' : Colors.textDark}/> :
                            (label || icon) ?
                                <>
                                    {icon && <i className={icon} style={{marginRight: label ? 12 :0, fontSize: 18}}/>}
                                    {label && <div style={{fontSize: 14}}>{label}</div>}

                                    {afterIcon && <i className={afterIcon} style={{marginLeft: label ? 12 :0, fontSize: 18}}/>}
                                </> :
                                children
                        }
                    </button>
                </ToolTip>
            )
        } else {
            const defaultStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: 3,
                borderWidth: 0,
                outline: 'none',
                background: Colors.backgroundDark,
                color: this.state.hover ? 'white' : Colors.textLight,
                cursor: 'pointer',
                height: 36,
                paddingLeft: 15,
                paddingRight: 15,
                ...buttonStyle
            }

            return (
                <ToolTip disabled={disabled || loading} text={tooltip} style={{...style, height: 36}}>
                    <button
                        tabIndex="-1"
                        disabled={disabled || loading}
                        style={defaultStyle}
                        type={type}
                        onClick={loading ? null : this.onClick.bind(this)}
                        onMouseDown={loading ? null : onMouseDown}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                        onFocus={() => this.setState({focus: false})}
                        onBlur={() => this.setState({focus: false})}
                    >
                        {loading ?
                            <Spinner name='ball-clip-rotate' fadeIn='none' color={variant === 'text' ? Colors.textDark : 'white'}/> :
                            <>
                                {icon &&<i className={icon} style={{marginRight: 12, fontSize: 18}}/>}
                                {label && <div style={{}}>{label}</div>}
                            </>

                        }
                    </button>
                </ToolTip>
            )
        }
    }
}

