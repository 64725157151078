import React from 'react'

import AddressActions from '../../actions/AddressActions.js'

import {Column, Input, Row} from './index.js'
import guid from '../../../utils/guid.js'

const regPostal = /^[0-9]{4} [A-Z]{2}$/

class PostalCodeAutocomplete extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            validation: {}
        }
    }

    componentDidUpdate(prevProps) {
        const {address, noAutoComplete} = this.props

        if (!noAutoComplete && (prevProps.address.postalCode !== address.postalCode || prevProps.address.nr !== address.nr || prevProps.address.addition !== address.addition)) {
            this.autocomplete(address)
        }
    }

    setPostalCode(event) {
        const validation = {...this.state.validation}

        if (validation.postalCode) {
            validation.postalCode = null
            this.setState({validation})
        }

        const address = {...this.props.address}

        let postalCode = event.target.value.toUpperCase().replace(/  +/g, ' ')

        if (/^[0-9]{4}[A-Z]{2}$/.test(postalCode)) {
            postalCode = `${postalCode.substr(0, 4)} ${postalCode.substr(4, 2)}`
        }

        if (/^[0-9]{4} [A-Z]{2} $/.test(postalCode)) {
            postalCode = postalCode.trim()
        }

        address.postalCode = postalCode

        delete address.position

        this.props.onChange(address)
    }

    setNr(event) {
        const validation = {...this.state.validation}

        if (validation.nr) {
            validation.nr = null
            this.setState({validation})
        }

        const address = {...this.props.address}

        address.nr = event.target.value.toUpperCase()

        delete address.position

        this.props.onChange(address)
    }

    setAddition(event) {
        const address = {...this.props.address}

        address.addition = event.target.value.toUpperCase()

        if (/[0-9]/.test(address.addition[0])) {
            address.addition = `-${address.addition}`
        } else if (address.addition[1] && /[-]/.test(address.addition[0]) && /[^0-9]/.test(address.addition[1])) {
            address.addition = address.addition.substr(1)
        }

        this.props.onChange(address)
    }

    autocomplete(address) {
        if (address.country === 'NL' && /^[0-9]{4} [A-Z]{2}$/.test(address.postalCode) && address.nr) {
            this.lastCallbackId = guid.generate()

            AddressActions.autocomplete(address, this.lastCallbackId, (err, addr, callbackId) => {
                if (err || callbackId !== this.lastCallbackId) {
                    return
                }
                // Important
                const address = JSON.parse(JSON.stringify(this.props.address))

                if (addr) {
                    if (addr.street) {
                        address.street = addr.street || ''
                    }

                    if (addr.city) {
                        address.city = addr.city || ''
                    }

                    address.isBusiness = !!addr.isBusiness

                    if (addr.position && addr.position.lat && addr.position.lng) {
                        address.position = addr.position
                    }

                    if (typeof addr.showWarning !== 'undefined') {
                        address.showWarning = addr.showWarning
                    }
                }

                this.props.onChange(address)
            })
        }
    }

    validate() {
        const {id = ''} = this.props
        const address = this.props.address
        const validation = {}
        let valid = true
        let focusId = ''


        if (address.country === 'NL' && !address.nr) {
            focusId = `nr${id}`
            validation.nr = true
            valid = false
        }

        if (!address.postalCode || (address.country === 'NL' && !regPostal.test(address.postalCode))) {
            focusId = `postalCode${id}`
            validation.postalCode = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})

        return valid
    }

    render() {
        const {validation} = this.state
        const {id = '', address, disabled} = this.props

        return (
            <Row>
                <Column>
                    <Input
                        id={`postalCode${id}`}
                        style={{marginRight: 6, minWidth: 100}}
                        label='Postcode'
                        info={address.postalCode && address.country === 'NL' && address.showWarning && 'Dit adres staat niet in onze database, controleer of dit adres geldig is.'}
                        infoIcon='mdi mdi-map-marker-alert'
                        value={address.postalCode}
                        disabled={disabled}
                        isInvalid={validation.postalCode}
                        onChange={this.setPostalCode.bind(this)}
                    />

                </Column>

                <Column>
                    <Input
                        id={`nr${id}`}
                        style={{minWidth: 100}}
                        label='Huisnummer'
                        value={address.nr}
                        disabled={disabled}
                        isInvalid={validation.nr}
                        onChange={this.setNr.bind(this)}
                    />

                    <Input
                        style={{minWidth: 100}}
                        label='Toevoeging'
                        value={address.addition}
                        disabled={disabled}
                        onChange={this.setAddition.bind(this)}
                    />
                </Column>
            </Row>
        )
    }
}

export default PostalCodeAutocomplete
