import React from 'react'

import moment from 'moment'
import _ from 'underscore'

import {Button, IconButton, DraggableList, Colors, H5, S1, Card} from '../UI/index.js'
import EditAddress from './EditAddress.js'


export default class Addresses extends React.Component {
    constructor(props) {
        super(props)
        const addressBook = JSON.parse(JSON.stringify(props.addressBook))

        const groupedByName = _.groupBy(addressBook, 'name')

        _.values(props.customers).map((customer) => {
            if (!groupedByName[customer.name]) {
                addressBook.push(customer.address)
            }
        })

        this.state = {
            addressBook
        }
    }

    onListChange(addresses) {
        const {onChange} = this.props

        if (addresses.length === 2 && addresses[0].type === 'delivery' && addresses[1].type === 'pickup') {
            addresses[0].type = 'pickup'

            addresses[1].type = 'delivery'
            addresses[1].gpsRequired = addresses[0].gpsRequired
            addresses[1].scanRequired = addresses[0].scanRequired
            addresses[1].signatureRequired = addresses[0].signatureRequired
            addresses[1].signatureNeighbors = addresses[0].signatureNeighbors
            addresses[1].statedAddressOnly = addresses[0].statedAddressOnly
            addresses[1].nameRequired = addresses[0].nameRequired
            addresses[1].imageRequired = addresses[0].imageRequired
            addresses[1].mailboxDelivery = addresses[0].mailboxDelivery
            addresses[1].minimumAge = addresses[0].minimumAge
            addresses[1].idCheck = addresses[0].idCheck


            delete addresses[0].signatureRequired
            delete addresses[0].signatureNeighbors
            delete addresses[0].statedAddressOnly
            delete addresses[0].nameRequired
            delete addresses[0].imageRequired
            delete addresses[0].mailboxDelivery
            delete addresses[0].minimumAge
            delete addresses[0].idCheck
        }

        onChange(addresses)
    }

    onClickAddAddress() {
        const {reseller, customer, onChange} = this.props
        const addresses = [...this.props.addresses]

        addresses.push({
            type: 'delivery',
            signatureRequired: customer.settings.orders.defaultSigned === 'default' ?
                !!reseller.settings.defaultSigned :
                !!customer.settings.orders.defaultSigned,
            statedAddressOnly: customer.settings.orders.defaultStatedAddressOnly === 'default' ?
                !!reseller.settings.defaultStatedAddressOnly :
                !!customer.settings.orders.defaultStatedAddressOnly,
            nameRequired: customer.settings.orders.nameRequired === 'default' ?
                !!reseller.settings.nameRequired :
                !!customer.settings.orders.nameRequired,
            scanRequired: customer.settings.orders.scanRequired === 'default' ?
                !!reseller.settings.scanRequired :
                !!customer.settings.orders.scanRequired,
            imageRequired: customer.settings.orders.imageRequired === 'default' ?
                !!reseller.settings.imageRequired :
                !!customer.settings.orders.imageRequired,
            gpsRequired: customer.settings.orders.gpsRequired === 'default' ?
                !!reseller.settings.gpsRequired :
                !!customer.settings.orders.gpsRequired,
            mailboxDelivery: false,
            name: '',
            attention: '',
            street: '',
            street2: '',
            postalCode: '',
            nr: '',
            addition: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                moment().format('HH:mm'),
            endTime: reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                moment().format('HH:mm')
        })

        onChange(addresses)
    }

    onClickRemoveAddress(index) {
        const {onChange} = this.props
        const addresses = [...this.props.addresses]
        addresses.splice(index, 1)
        onChange(addresses)
    }

    onAddressChange(index, address, getPrice) {
        const {onChange} = this.props


        const addresses = [...this.props.addresses]

        const originalStartTime = addresses[index].startTime
        const originalEndTime = addresses[index].endTime

        addresses[index] = {...address}

        addresses.map((address, addressIndex) => {
            if (addressIndex > index) {
                if (addresses[addressIndex].startTime === originalStartTime) {
                    addresses[addressIndex].startTime = addresses[index].startTime
                }

                if (addresses[addressIndex].endTime === originalEndTime) {
                    addresses[addressIndex].endTime = addresses[index].endTime
                }
            }
        })

        onChange(addresses, getPrice)
    }

    setCustomer(value) {
        this.address.setName({target: {value}})
    }

    focus(index) {
        index = index || 0
        const element = document.getElementById(`nameaddress${index}`)
        const addresses = [...this.props.addresses]

        if (element && !addresses[index].name) {
            setTimeout(() => {
                element.focus()
            }, 10) // Timeout to prevent focus from being overwritten by the popover
        }
    }

    render() {
        const {addressBook} = this.state
        const {addresses, showAddAddressButton, showDays, availableDays} = this.props


        return (
            <>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 24}}>
                    <H5 style={{flex: 1}}>Adressen</H5>
                </div>

                <DraggableList
                    items={addresses}
                    onChange={this.onListChange.bind(this)}
                    renderItem={(address, DragHandle, index) => {
                        return (
                            <Card style={{width: '100%', background: Colors.grey20, marginBottom: 12}}>

                                <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <S1 style={{flex: 1, marginLeft: 12}}>{address.isBusiness && address.name ? address.name : (`${address.street} ${address.nr}${address.addition}`.trim() || `Adres ${index + 1}`)}</S1>

                                    <DragHandle/>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                                        {addresses.length > 1 &&
                                            <IconButton onClick={this.onClickRemoveAddress.bind(this, index)}>
                                                <i className='mdi mdi-delete'/>
                                            </IconButton>
                                        }
                                    </div>
                                </div>

                                <EditAddress
                                    id={`address${index}`}
                                    editAddressTypeAllowed={true}
                                    address={address}
                                    addressBook={addressBook}
                                    showDays={showDays}
                                    availableDays={availableDays}
                                    onConfirm={this.focus.bind(this, index + 1)}
                                    onChange={this.onAddressChange.bind(this, index)}
                                    allowAttachments={true}
                                    ref={(ref) => {
                                        if (index === 0) {
                                            this.address = ref
                                        }
                                    }}
                                />
                            </Card>
                        )
                    }}
                />

                {showAddAddressButton &&
                    <div style={{display: 'flex'}}>
                        <Button
                            style={{marginBottom: 12}}
                            variant='outline-white'
                            icon='mdi mdi-plus'
                            tooltip='Nieuw adres'
                            onClick={this.onClickAddAddress.bind(this)}
                        />
                    </div>
                }
            </>
        )
    }
}

